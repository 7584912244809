import ApiService from "../../api_service";
import * as statusMapper from "@/service/error_request_mapper.js";

export class EcoSystemsService extends ApiService {
  constructor(api) {
    super(api);
  }
  async updateEcosystem (entity) {
    let id = entity.id;
    delete entity.id;
    this.api.updateEcosystem (id, entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(entity);
      converter.convert(error);
    });
  }
}
